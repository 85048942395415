import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../../components/Layout/Layout'
import Seo from '../../components/Seo/Seo'
import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: 1080px;
  margin: 160px 20px 0 20px;

  @media screen and (min-width: 1080px) {
    margin: 80px auto 0 auto;
  }
  

  .gatsby-resp-image-wrapper {
    margin: 0!important;
    border-radius: 5px;
  }

`
const FilmographyPage = ({ data }) => {
  return (
    <>
      <Seo title={data.mdx.frontmatter.title} />
      <Layout>
        <Wrapper>
          <h1>{data.mdx.frontmatter.title}</h1>
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </Wrapper>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
      }
      body
    }
  }
`

export default FilmographyPage
